import { APICore } from './apiCore';

const api = new APICore();

function addInv(params: { name: string, }) {
  //console.log('addinv---');
    const baseUrl = '/add-inv';
    return api.create(`${baseUrl}`, params);
}
function addInvSale(params: { entrydate:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,trackNumber:string,shipped_via:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,invoicedetdata:Object }) {
    const baseUrl = '/add-sale-invoice';
    return api.create(`${baseUrl}`, params);
}
function addPos_API(params: {customerid:string,itemprice:boolean,showitems:boolean,showtax:boolean,fullscreen:boolean}) {
  const baseUrl = '/update-pos-configurations';
  return api.create(`${baseUrl}`, params);
}
function updInvSale(params: { id:string,entrydate:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,trackNumber:string,shipped_via:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,invoicedetdata:Object }) {
    const baseUrl = `/update-salesinvoice/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addPOSSale(params) {
  //console.log(params);
    const baseUrl = params.status=='hold'?'/hold-pos-invoice':'/add-pos-invoice';
    return api.create(`${baseUrl}`, params);
}
function updPOSSale(params) {
    const baseUrl = `/update-posinvoice/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addInvReturn_API(params: { entrydate:string,invoicenumber:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,trackNumber:string,shipped_via:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,invoicedetdata:Object }) {
  //console.log('addinv---');
    const baseUrl = '/add-invoice-return';
    return api.create(`${baseUrl}`, params);
}
function updInvReturn_API(params: { id:string,entrydate:string,invoicenumber:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string|null,freight:string,trackNumber:string,shipped_via:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,invoicedetdata:Object }) {
  //console.log('update sale inv---');
    const baseUrl = `/update-invoicereturn/${params.id}`;
    return api.update(`${baseUrl}`, params);
}
function addInvGrid(params: { data:Object }) {
  //console.log('addinv---');
    const baseUrl = '/add-inv';
    return api.create(`${baseUrl}`, params);
}
function add_payReceipt(params: { customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string }) {
  //console.log('add payment---');
    const baseUrl = '/add-receipt';
    return api.create(`${baseUrl}`, params);
}
function upd_payReceipt(params: { id:string,customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string ,reference:string}) {
  //console.log('add payment---');
    const baseUrl = '/update-receipt/'+params.id;
    return api.update(`${baseUrl}`, params);
}
function addCedit_API(params) {
    const baseUrl = '/add-creditnote';
    return api.create(`${baseUrl}`, params);
    
}
function updCedit_API(params) {
    const baseUrl = '/update-creditnote/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}
function addDebit_API(params) {
    const baseUrl = '/add-debitnote';
    return api.create(`${baseUrl}`, params);
    
}
function updDebit_API(params) {
    const baseUrl = '/update-debitnote/'+params.id;
    return api.create(`${baseUrl}`, params);
    
}

export {addPOSSale,
  updPOSSale,addInvReturn_API,
  updCedit_API,
  addDebit_API,
  addCedit_API,
updDebit_API,
updInvReturn_API, addInv,addInvGrid ,addInvSale,updInvSale,addPos_API,add_payReceipt,upd_payReceipt};
