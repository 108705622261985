// constants
import { SalesActionTypes } from './constants';

export type SalesActionType = {
    type:
        | SalesActionTypes.API_RESPONSE_SUCCESS
        | SalesActionTypes.API_RESPONSE_ERROR
        | SalesActionTypes.RESET
        | SalesActionTypes.ADD_Inv
        | SalesActionTypes.ADD_SALEINVOICE
        | SalesActionTypes.UPD_SALEINVOICE
        | SalesActionTypes.ADD_INVOICERETURN
        | SalesActionTypes.UPD_POSCONFIG
        | SalesActionTypes.UPD_INVOICERETURN
        | SalesActionTypes.ADD_POS
        | SalesActionTypes.UPD_POS
        | SalesActionTypes.ADD_INVGRID;
    payload: {} | string;
};

type InvoiceData = {
    name: string;
    


};

// common success
export const salesApiResponseSuccess = (actionType: string, data: InvoiceData | {}): SalesActionType => ({
    type: SalesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const salesApiResponseError = (actionType: string, error: string): SalesActionType => ({
    type: SalesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addInv = (name:string): SalesActionType => ({
    type: SalesActionTypes.ADD_Inv,
    payload: {name, },
});

export const addSaleInv = (entrydate:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,trackNumber:string,shipped_via:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_SALEINVOICE,
    payload: {entrydate,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata },
});

export const updPosConfigrations = (customerid,itemprice,showitems,showtax,fullscreen): SalesActionType => ({
    type: SalesActionTypes.UPD_POSCONFIG,
    payload: {customerid,itemprice,showitems,showtax,fullscreen},
});

export const updSaleInv = (id:string,entrydate:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,trackNumber:string,shipped_via:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.UPD_SALEINVOICE,
    payload: {id,entrydate,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata },
});
export const addPOSInv = (entrydate:string,
    sales_categoriesid:string,
    discount:string,
    totalprice:string,
    cashreceived:string,
    subtotal:string,
    invoicetotal:string,
    taxpercent:string,
    changeamount:string,
    taxamount:string,
    cashcounterid:string,
    invoicenumber:string,
    bankid:string,
    bankreceived:string,
    status:string,
    phone:string,phonecode:string,name:string,
    invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_POS,
    payload: {entrydate,sales_categoriesid,discount,totalprice,cashreceived,subtotal,invoicetotal,taxpercent,changeamount,taxamount,cashcounterid,invoicenumber,        bankid,bankreceived,status,phone,phonecode,name,invoicedetdata},
});
export const updPOSInv = (id:string,entrydate:string,
    sales_categoriesid:string,
    discount:string,
    totalprice:string,
    cashreceived:string,
    subtotal:string,
    invoicetotal:string,
    taxpercent:string,
    taxamount:string,
    cashcounterid:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.UPD_POS,
    payload: {id,entrydate,sales_categoriesid,discount,totalprice,cashreceived,subtotal,invoicetotal,taxpercent,taxamount,cashcounterid,invoicedetdata },
});
export const addInvReturn = (entrydate:string,invoicenumber:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,trackNumber:string,shipped_via:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_INVOICERETURN,
    payload: {entrydate,invoicenumber,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata },
});
export const editInvReturn = (id:string,entrydate:string,invoicenumber:string,customerid:string,sales_categoriesid:string,salesmanid:string,reference:string,notes:string,invoicetermsid:string,freight:string,trackNumber:string,shipped_via:string,totalDiscount:string,totalprice:string,AdvancePayment:string,subtotal:string,expense:string,invoicetotal:string,custfreight:boolean,custexpense:boolean,name:string,phone:string,address:string,tax:string,taxamount:string,invoicedetdata:Object): SalesActionType => ({
    type: SalesActionTypes.UPD_INVOICERETURN,
    payload: {id,entrydate,invoicenumber,customerid,sales_categoriesid,salesmanid,reference,notes,invoicetermsid,freight,trackNumber,shipped_via,totalDiscount,totalprice,AdvancePayment,subtotal,expense,invoicetotal,custfreight,custexpense,name,phone,address,tax,taxamount,invoicedetdata },
});
export const addInvGrid = (data:Object): SalesActionType => ({
    type: SalesActionTypes.ADD_INVGRID,
    payload: {data },
});
export const payment_receipt = (customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string) => ({
    type: SalesActionTypes.PAYMENT_RECEIPT,
    payload: {customerid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference },
});
export const upd_payment_receipt = (id:string,customerid:string,amount:string,paymethod:string,salesmanid:string,description:string,entrydate:string,checkNum:string,bank:string,duedate:string,deposit:string,reference:string) => ({
    type: SalesActionTypes.UPD_PAYMENT_RECEIPT,
    payload: {id,customerid,amount,paymethod,salesmanid,description,entrydate,checkNum,bank,duedate,deposit,reference },
});


export const addCreditNote = (entrydate,customerid,description,totalamount,voucherdetdata) => ({
    type: SalesActionTypes.CREDIT_NOTE,
    payload: {
    entrydate,customerid,description,totalamount,voucherdetdata
    },
});
export const addDebitNote = (entrydate,customerid,description,totalamount,voucherdetdata) => ({
    type: SalesActionTypes.DEBIT_NOTE,
    payload: {
    entrydate,customerid,description,totalamount,voucherdetdata
    },
});
export const updCreditNote = (id,entrydate,customerid,description,totalamount,voucherdetdata) => ({
    type: SalesActionTypes.UPD_CREDIT_NOTE,
    payload: {
    id,entrydate,customerid,description,totalamount,voucherdetdata
    },
});
export const updDebitNote = (id,entrydate,customerid,description,totalamount,voucherdetdata) => ({
    type: SalesActionTypes.UPD_DEBIT,
    payload: {
   id, entrydate,customerid,description,totalamount,voucherdetdata
    },
});


export const resetSales = (): SalesActionType => ({
    type: SalesActionTypes.RESET,
    payload: {},
});
